<template>
  <b-modal
    ref="modalUpsertTemplate"
    :visible="createCourseSectionActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`New Course Section`"
    ok-title="Create Section"
    @ok="createEntity"
    body-class="pt-0"
    centered
  >
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <label class="d-block">Course</label>
        <div class="d-flex align-items-center mb-1 border rounded p-50">
          <b-avatar :src="courseData.avatar" :text="avatarText(courseData.name)" size="45px" variant="light-info" class="mr-75" rounded />
          <h2 class="mb-0 font-weight-bolder">{{ courseData.name }}</h2>
        </div>
      </b-col>
      <b-col cols="12" md="8" lg="8">
        <b-form-group label="Instructor" label-for="course-section-instructor">
          <vue-autosuggest
            id="course-section-instructor"
            ref="autocomplete"
            v-model="instructorQuery"
            :suggestions="instructorSuggestions"
            :input-props="instructorInputProps"
            :section-configs="instructorSectionConfigs"
            :render-suggestion="instructorRenderSuggestion"
            @input="instructorFetchResults"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Visibility" label-for="course-section-visibility">
          <v-select
            v-model="courseSectionCreateData.visibility"
            :options="visibilityOptions"
            :clearable="false"
            :reduce="(val) => val.value"
            required
            transition="smooth"
            input-id="course-section-visibility"
          />
        </b-form-group>
      </b-col>

      <!-- <b-col cols="12" md="12" lg="12">
        <b-form-group label="Section Alias" label-for="course-section-nickname">
          <b-form-input id="course-section-nickname" placeholder="" v-model="courseSectionCreateData.nickname" />
        </b-form-group>
      </b-col> -->
      <!--      
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Status" label-for="course-section-status">
          <v-select
            v-model="courseSectionCreateData.status"
            :options="statusOptions"
            :clearable="false"
            :reduce="(val) => val.value"
            required
            transition="smooth"
            input-id="course-section-status"
          />
        </b-form-group>
      </b-col> -->
    </b-row>
    <!-- <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="secondary" class="ml-75" @click="closeModal"> Close </b-button>
        <b-button variant="primary" class="ml-75" @click="createEntity"> Create Section </b-button>
      </div>
    </template> -->
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BModal,
  BRow,
  VBModal,
  BAvatar,
} from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import userStoreModule from '@/store/user/userStoreModule';
import { ref, onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useCourseSections from '../useCourseSections';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: 'CourseSectionCreateModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    BAvatar,
    quillEditor,
    vSelect,
    VueAutosuggest,
  },
  directives: { 'b-modal': VBModal },
  props: {
    createCourseSectionActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    courseData: {
      type: Object,
      default: undefined,
      default: false,
    },
  },
  watch: {
    '$props.createCourseSectionActive'(val) {
      this.initModal();
    },
  },
  mounted() {
    this.initModal();
  },
  methods: {
    initModal() {
      this.courseSectionCreateData = { visibility: 'public', status: 'draft', classes: [] };
      this.instructorFetchResults();
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:create-course-section-active', false);
    },
    createEntity(event) {
      this.busy = true;

      event.preventDefault();

      const pl = { course: this.courseData.id, ...this.courseSectionCreateData };

      if (this.instructorSelected) {
        pl.instructor = this.instructorSelected.id;
      }

      store
        .dispatch('rosterStoreModule/createCourseSection', { courseSectionData: pl })
        .then((response) => {
          // this.$emit('update:course-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Course Section Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          return this.$router.push(`/roster/courses/sections/${response.data.id}`);
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit course section',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    instructorFetchResults() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.instructorSelected = null;

        const usersPromise = this.$store.dispatch('userStoreModule/fetchUsers', {
          search: this.instructorQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.instructorSuggestions = [];

          const users = values[0].data.results;

          users.length && this.instructorSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    instructorRenderSuggestion(suggestion) {
      const user = suggestion.item;
      return (
        <div class="d-flex">
          <div>
            <b-avatar src={user.avatar} text={avatarText(user.name)} class="mr-50" variant="light-info" size="30" />
          </div>
          <div class="d-flex align-items-center">
            <h4 class="font-weight-normal mb-0">{user.name}</h4>
          </div>
        </div>
      );
    },
  },
  setup(props) {
    const courseSectionCreateData = ref({});
    const busy = ref(false);

    const instructorQuery = ref('');
    const instructorSuggestions = ref([]);
    const instructorSelected = ref(undefined);
    const instructorSectionConfigs = {
      users: {
        limit: 3,
        onSelected: (selected) => {
          instructorSelected.value = selected.item;
        },
      },
    };

    const instructorInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search For Instructor',
      class: 'form-control',
      name: 'ajax',
    };

    const { statusOptions, visibilityOptions } = useCourseSections();

    return {
      avatarText,
      busy,

      courseSectionCreateData,

      instructorQuery,
      instructorSuggestions,
      instructorSectionConfigs,
      instructorInputProps,
      instructorSelected,

      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
