<template>
  <div class="container content-p">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="courseData === undefined">
      <h4 class="alert-heading">Error fetching course data</h4>
      <div class="alert-body">
        No course found with this course id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-course-list-pending' }"> Course List </b-link>
        for other courses.
      </div>
    </b-alert>

    <div v-if="courseData" :key="courseData.id">
      <course-edit-modal :edit-course-active.sync="editCourseActive" :course-data="courseData" @refetch-data="fetchData" />
      <course-section-create-modal
        v-if="$can('roster.section.create')"
        :create-course-section-active.sync="createCourseSectionActive"
        :course-data="courseData"
        @refetch-data="fetchData"
      />

      <b-row class="mb-25">
        <b-col cols="12" md="12">
          <course-view-header :course-data="courseData" :edit-course-active.sync="editCourseActive" @refetch-data="fetchData" />
        </b-col>
      </b-row>

      <b-tabs nav-wrapper-class=" " nav-class="mb-2" lazy>
        <b-tab title="Sections" @click="changeTab('all')">
          <template #title>
            <lucide-icon name="List" style="width: 15px; height: 15px" />
            All Sections
          </template>
        </b-tab>
        <b-tab title="Profiles" @click="changeTab('live')">
          <template #title>
            <lucide-icon name="CircleDot" style="width: 15px; height: 15px" />
            Live Sections
          </template>
        </b-tab>
        <b-tab title="Sections" @click="changeTab('upcoming')">
          <template #title>
            <lucide-icon name="Clock" style="width: 15px; height: 15px" />
            Upcoming Sections
          </template>
        </b-tab>
      </b-tabs>

      <b-row>
        <b-col v-if="showingTab === 'live'" cols="12" lg="12" md="12">
          <course-view-section-list
            v-if="$can('roster.section.list')"
            :key="showingTab"
            :course-data.sync="courseData"
            :create-course-section-active.sync="createCourseSectionActive"
            display="live"
          />
          <h3 v-else>You are not authorized to view sections</h3>
        </b-col>
        <b-col v-if="showingTab === 'upcoming'" cols="12" lg="12" md="12">
          <course-view-section-list
            v-if="$can('roster.section.list')"
            :key="showingTab"
            :course-data.sync="courseData"
            :create-course-section-active.sync="createCourseSectionActive"
            display="upcoming"
          />
          <h3 v-else>You are not authorized to view sections</h3>
        </b-col>

        <b-col v-if="showingTab === 'all'" cols="12" lg="12" md="12">
          <course-view-section-list
            v-if="$can('roster.section.list')"
            :key="showingTab"
            :course-data.sync="courseData"
            :create-course-section-active.sync="createCourseSectionActive"
            display="all"
          />
          <h3 v-else>You are not authorized to view sections</h3>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink, BButton, BCard, BTab, BTabs } from 'bootstrap-vue';
import vSelect from 'vue-select';
import CourseViewHeader from './CourseViewHeader.vue';
import CourseViewSectionList from './CourseViewSectionList.vue';
import CourseEditModal from '../edit/CourseEditModal.vue';
import { avatarText } from '@core/utils/filter';
import CourseSectionCreateModal from '../sections/create/CourseSectionCreateModal.vue';
import { title } from '@core/utils/filter';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BTab,
    BTabs,

    vSelect,

    // Local Components
    CourseViewHeader,
    CourseViewSectionList,
    CourseEditModal,
    CourseSectionCreateModal,

    avatarText,
  },
  metaInfo() {
    const title = this.courseData ? `${this.courseData.name}` : 'View Course';
    return {
      title,
    };
  },
  watch: {
    '$route.path'() {
      this.fetchData();
    },
  },
  setup() {
    const courseData = ref(null);
    const showingTab = ref('all');

    const editCourseActive = ref(false);
    const createCourseSectionActive = ref(false);

    const fetchData = () => {
      store
        .dispatch('rosterStoreModule/fetchCourse', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'courses' },
        })
        .then((response) => {
          courseData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            courseData.value = undefined;
          }
        });
    };

    const changeTab = (tab) => {
      showingTab.value = tab;
    };

    fetchData();

    return {
      courseData,
      fetchData,
      showingTab,
      changeTab,
      editCourseActive,
      createCourseSectionActive,
      title,
    };
  },
};
</script>

<style></style>
