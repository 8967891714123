<template>
  <div>
    <div
      @contextmenu.prevent="$refs.courseViewEditMenu.open"
      :style="`${calculateBorderColor(courseColor)} padding: 0.075rem`"
      class="mb-1 rounded position-relative overflow-hidden drop-shadow-default"
    >
      <div class="rounded" style="background: #191a22">
        <div class="card-body rounded z-2 position-relative" :style="calculateBackgroundColor(courseColor)">
          <div class="d-flex align-items-center">
            <b-avatar
              :src="courseData.avatar"
              :text="avatarText(courseData.name)"
              size="65px"
              variant="light-info"
              class="mr-1"
              style="margin-top: 3px"
              rounded
            />
            <div class="d-flex flex-column align-items-start justify-content-center">
              <div class="d-flex align-items-center mb-25">
                <h4 class="font-weight-bolder tracking-tighter mr-50" style="font-size: 1.85rem; line-height: 2.2rem; margin-bottom: 2px">
                  {{ courseData.name }}
                </h4>
                <feather-icon
                  v-if="$can('roster.course.update')"
                  icon="EditIcon"
                  size="13"
                  class="cursor-pointer text-muted"
                  style="margin-top: 1px"
                  @click="$emit('update:edit-course-active', true)"
                />
              </div>

              <div class="d-flex align-items-center">
                <b-badge class="profile-badge mr-25" :variant="`${resolveCourseStatusVariant(courseData.status)}`">
                  <feather-icon :icon="resolveCourseStatusIcon(courseData.status)" size="12" />
                  {{ title(courseData.status) }}
                </b-badge>
                <b-badge class="profile-badge" :variant="`light-${resolveCourseVisibilityVariant(courseData.visibility)}`">
                  <feather-icon :icon="resolveCourseVisibilityIcon(courseData.visibility)" size="12" />
                  {{ title(courseData.visibility) }}
                </b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-context ref="courseViewEditMenu" class="mw-8">
      <li>
        <a
          v-if="$can('roster.course.update')"
          class="d-flex align-items-center justify-content-between"
          @click="$emit('update:edit-course-active', true)"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a v-if="$can('roster.course.delete')" class="d-flex align-items-center justify-content-between" @click="archiveCourse">
          <span>Archive</span>
          <feather-icon icon="TrashIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge, BLink } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useCourses from '../useCourses';
import { title } from '@core/utils/filter';
import useAppConfig from '@core/app-config/useAppConfig';
import VueContext from 'vue-context';
import store from '@/store';
import rosterStoreModule from '../../../../../store/roster/rosterStoreModule';
import { onUnmounted } from '@vue/composition-api';
import { hexToRGB } from '@/libs/utils/color';
import _ from 'lodash';

const { skin } = useAppConfig();

export default {
  components: {
    BLink,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    VueContext,
  },
  props: {
    courseData: {
      type: Object,
      required: true,
    },
    editCourseActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    courseColor() {
      if (this.courseData.metadata && this.courseData.metadata.design && this.courseData.metadata.design.color) {
        return this.courseData.metadata.design.color;
      }
      return '#5b5b5e';
    },
  },
  methods: {
    archiveCourse() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this course?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/updateCourse', {
              courseId: this.courseData.id,
              courseData: { status: 'archived' },
            })
            .then((response) => {
              this.$emit('refetch-data');

              // this.refetchData();
            });
        }
      });
    },
    calculateBackgroundColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.35) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05) 93.34%)`;
    },
    calculateBorderColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(190deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.03) 100%);`;
    },
  },
  setup() {
    const { resolveCourseStatusVariant, resolveCourseStatusIcon, resolveCourseVisibilityVariant, resolveCourseVisibilityIcon } =
      useCourses();
    return {
      avatarText,
      resolveCourseStatusVariant,
      resolveCourseStatusIcon,
      resolveCourseVisibilityIcon,
      resolveCourseVisibilityVariant,
      title,
      skin,
    };
  },
};
</script>

<style></style>
