<template>
  <div class="pr-50">
    <course-section-edit-modal
      :edit-course-section-active.sync="editCourseSectionActive"
      :course-section-data="editCourseSectionData"
      @refetch-data="refetchData"
      v-if="$can('roster.section.update')"
    />

    <div class="d-flex align-items-center mb-75">
      <h3 class="mb-0 mr-50">{{ title(display) }} Sections</h3>
      <div
        v-if="$can('roster.section.create')"
        @click="$emit('update:create-course-section-active', true)"
        class="d-flex align-items-center text-muted cursor-pointer"
        style="margin-top: 3px; margin-left: 3px"
      >
        <feather-icon icon="PlusCircleIcon" size="18" class="mr-25" />
        <span>New Section</span>
      </div>
    </div>

    <div class="mb-75">
      <b-row>
        <b-col cols="12" sm="8" md="7">
          <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
            <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 20rem" />
            <session-list-filters
              :visibility-filter.sync="visibilityFilter"
              :status-filter.sync="statusFilter"
              :visibility-options="visibilityOptions"
              :status-options="statusOptions"
            />
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="5" class="d-flex align-items-center justify-content-end"></b-col>
      </b-row>
    </div>

    <b-card no-body class="mb-0 table-no-top">
      <b-table
        ref="refCourseListTable"
        class="position-relative"
        :items="fetchCourseSections"
        :fields="tableColumnsCourseView"
        responsive
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :sort-by-formatted="true"
        sort-icon-left
        show-empty
        empty-text="No matching records found"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Section -->
        <template #cell(startDate)="data">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="font-weight-bold text-nowrap text-white mb-0"
                style="font-size: 1.25rem; line-height: 1.75rem"
              >
                {{ resolveCourseSectionName(data.item) }}
              </b-link>
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                :class="resolveCourseSectionDateMessageVariant(data.item.startDate, data.item.endDate)"
              >
                <small style="font-size: 1.05rem; line-height: 1.35rem" class="d-flex align-items-center">
                  <feather-icon
                    :icon="resolveCourseSectionDateMessageIcon(data.item.startDate, data.item.endDate)"
                    size="14"
                    class="mr-25"
                    style="margin-top: 1px"
                  />
                  {{ resolveCourseSectionDateMessage(data.item.startDate, data.item.endDate) }}</small
                >
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Instructor -->
        <template #cell(instructor)="data">
          <div
            class="d-flex align-items-center cursor-pointer"
            v-if="data.item.instructor && data.item.instructor.id"
            @click="$router.push({ name: 'apps-users-view', params: { id: data.item.instructor.id } })"
            style="gap: 0.625rem"
          >
            <b-avatar
              size="32"
              :src="data.item.instructor.avatar"
              :text="avatarText(data.item.instructor.name)"
              :variant="`light-${resolveUserRoleVariant(data.item.instructor.role)}`"
            />
            <div class="d-flex flex-column" style="gap: 0">
              <div class="font-weight-bold text-nowrap text-white mb-0" style="line-height: 1.3rem">
                {{ data.item.instructor.name }}
              </div>
              <div class="d-flex align-items-center text-muted">
                <small class="text-muted">@{{ data.item.instructor.username }}</small>
              </div>
            </div>
          </div>
          <span v-else class="text-muted text-nowrap">No Instructor Assigned</span>
        </template>

        <!-- Column: Total Students -->
        <template #cell(profiles)="data">
          <span :id="`student-list-status-${data.item.id}`" :style="data.item.profiles.length && 'cursor: help'">{{
            data.item.profiles.length
          }}</span>
          <student-list-statuses
            v-if="data.item.profiles.length"
            :target-id="`student-list-status-${data.item.id}`"
            :data="data.item.statistics"
          />
        </template>

        <!-- Column: Direct Students -->
        <template #cell(direct_profiles)="data">
          <span :id="`direct-student-list-status-${data.item.id}`" :style="data.item.courseCohort.profiles.length && 'cursor: help'">{{
            data.item.courseCohort.profiles.length
          }}</span>
          <student-list-statuses
            v-if="data.item.courseCohort.profiles.length"
            :target-id="`direct-student-list-status-${data.item.id}`"
            :data="data.item.courseCohort.statistics"
          />
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolveCourseSectionVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveCourseSectionStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item v-if="$can('roster.section.update')" @click="editCourseSection(data.item)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('roster.section.get')"
              @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>View</span>
                <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCourseSections"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <vue-context ref="courseViewSectionListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          v-if="$can('roster.section.update')"
          class="d-flex align-items-center justify-content-between"
          @click="editCourseSection(data.item)"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.section.get')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BFormInput,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownDivider,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import useCourseSections from '../sections/useCourseSections';
import SessionListFilters from '../sections/list/CourseSectionListFilters.vue';
import VueContext from 'vue-context';
import CourseSectionEditModal from '@/views/apps/roster/courses/sections/edit/CourseSectionEditModal.vue';
import StudentListStatuses from '../../pathways/cohorts/view/components/StudentListStatuses.vue';
import { ref, onMounted } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

export default {
  components: {
    SessionListFilters,

    BRow,
    BCol,
    BAlert,
    BLink,
    BTable,

    BCard,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownDivider,

    vSelect,
    VueContext,

    CourseSectionEditModal,
    StudentListStatuses,
    BTooltip,
  },
  methods: {
    archiveSession(sessionId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this session?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/updateSession', {
              sessionId,
              sessionData: { status: 'archived' },
            })
            .then((response) => {
              this.fetchData();
            });
        }
      });
    },
    editCourseSection(data) {
      this.editCourseSectionData = data;
      this.editCourseSectionActive = true;
    },
    onRowContextMenu(item, index, event) {
      this.$refs.courseViewSectionListMenu.open(event, { item });
      event.preventDefault();
    },
  },
  props: {
    courseData: {
      type: Object,
      required: true,
    },
    createCourseSectionActive: {
      type: Boolean,
      default: false,
      required: true,
    },
    display: {
      type: String,
      default: 'all',
      required: false,
    },
  },
  watch: {
    '$props.courseData'() {
      this.refetchData();
    },
  },
  setup(props) {
    const editCourseSectionData = ref({});
    const editCourseSectionActive = ref(false);

    const { courseData } = props;

    const {
      fetchCourseSections,
      tableColumnsCourseView,
      perPage,
      currentPage,
      totalCourseSections,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      isLoaded,

      statusOptions,
      visibilityOptions,

      // UI
      resolveUserRoleVariant,
      resolveCourseSectionName,
      resolveCourseSectionSectionDateShort,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageVariant,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionDateMessageIcon,

      // Extra Filters
      visibilityFilter,
      statusFilter,
      displayFilter,
    } = useCourseSections(courseData);

    onMounted(() => {
      if (props.display && props.display !== 'all') {
        sortBy.value = 'startAt';
        isSortDirDesc.value = true;
        perPage.value = 5;
        displayFilter.value = props.display;
      }
    });

    return {
      avatarText,
      fetchCourseSections,
      tableColumnsCourseView,
      perPage,
      currentPage,
      totalCourseSections,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      isLoaded,

      statusOptions,
      visibilityOptions,

      avatarText,
      title,

      // UI
      resolveUserRoleVariant,
      resolveCourseSectionDateMessage,
      resolveCourseSectionDateMessageVariant,
      resolveCourseSectionSectionDateShort,
      resolveCourseSectionName,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionDateMessageIcon,

      // Extra Filters
      visibilityFilter,
      statusFilter,

      editCourseSectionData,
      editCourseSectionActive,
    };
  },
};
</script>

<style></style>
